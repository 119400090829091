html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Noto Serif', 'Inter', Arial, Helvetica, sans-serif;
}


.annotation-canvas {
  border-radius: 6px;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M26.4 2.4l3.2 3.2-22.4 22.4-3.2-3.2 22.4-22.4z" fill="black"/></svg>')
      0 32,
    auto;
}
